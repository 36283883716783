/*
    --------------------------
    # Site Name: Sierratequila.com
    # Developer: Nebojša Božer
    --------------------------

    # CONTENTS
    1. Responsive Subscribers
    2. Responsive Menu
    3. Section scroll
    4. Smooth scroll
    4. Age confirm
*/

/* -------------------------
# 1. Responsive Subscribers
---------------------------- */
function Responsive(initProp) {
    var inst = this;
    var subscribers = {};
    var defaultBrakePoints = {};
    var brakePoints = [];
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.sortNumber = function(a, b) {  
        return a - b;  
    }

    this.init = function() {
        defaultBrakePoints = initProp;

        for(key in defaultBrakePoints) {
            key = defaultBrakePoints[key];
            brakePoints.push(key);
            subscribers[key] = [];
        }
        brakePoints.sort(inst.sortNumber);

        window.onresize = function () {
            inst.update();
        }
    }; this.init();

    this.subscribe = function(fun, prop) {
        if(typeof(fun) == 'function') {
            if(typeof(prop) == 'string') {
                if(defaultBrakePoints[prop]) {
                    prop = defaultBrakePoints[prop];

                    //Assign function to default break point
                    subscribers[prop].push(fun);
                } else {
                    return false
                }
            } else if(typeof(prop) == 'number') {
                if(subscribers[prop]) {
                    //Assign function to existing break point
                    subscribers[prop].push(fun);
                } else {
                    brakePoints.push(prop);
                    brakePoints.sort(inst.sortNumber)
                    //Assign function to custom break point
                    subscribers[prop] = [];
                    subscribers[prop].push(fun);
                }
            } else {
                //Assign function to all break points
                for(key in subscribers) {
                    subscribers[key].push(fun);
                    
                }
            }

            this.update();
            return true;
        } else {
            return false;
        }
    }

    this.update = function() {
        inst.width = window.innerWidth;
        inst.height = window.innerHeight;

        for(var i=0; i < brakePoints.length; i++) {
            if( inst.width >= brakePoints[i] ) {
                // Run all subscribed functions greater then minimum width
                for(var j=0; j < subscribers[brakePoints[i]].length; j++) {
                    subscribers[brakePoints[i]][j]();
                }
            }
        }
    }
}; var responsive = new Responsive({
    xs: 442,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
});

/* -------------------
# 2. Responsive Menu
------------------- */
( function($){
    if(typeof($) != 'undefined') {
        $brandMobile = $(" [data-brand='mobile'] ");
        $brandDesktop = $(" [data-brand='desktop'] ");
        $burger = $(" [data-burger] ");
        $navigation = $(" [data-navigation] ");

        function toggleHidden($hide, $show, className) {
            if( $hide !== false && !$hide.hasClass('hidden') ) {
                $hide.addClass('hidden');
            }

            if( $show !== false && $show.hasClass('hidden') ) {
                $show.removeClass('hidden');
            }
        }

        function removeClass($el, className) {
            if( $el.hasClass(className) ) {
                $el.removeClass(className);
            }
        }

        function events() {
            $burger.click(function(){
                $burger.toggleClass('close');
                $navigation.toggleClass('expended');
            });
        } events();

        responsive.subscribe(function(){
            toggleHidden($brandMobile, $brandDesktop);
            toggleHidden($burger, false);
            removeClass($burger, 'close');
            removeClass($navigation, 'expended');
        }, 'lg');

        responsive.subscribe(function(){
            toggleHidden($brandDesktop, $brandMobile);
            toggleHidden(false, $burger);
        }, 0);
    }
} )(jQuery);

/* -------------------
# 3. Section Scroll
------------------- */
( function($){
    if(typeof($) != 'undefined') {
        var $window = $(window);
        var $html = $('html');
        var $body = $('body');
        var scrollTop = false;
        var $splashLoad = $('.splash-load');
        var hasSplashLoad = ( $splashLoad.length > 0 ) ? true : false;
        var $welcome = $('#dobrodosli');
        var $hatDown = $('[data-intro="down"]');
        var introOffset = $welcome.offset().top;
        /*$nav  = $('.site-navigation');
        var navHeight = $nav.height();*/

        var $circleLoad = $('[data-intro="circle"]');
        var $circleImage = $('[data-intro="logo"]');

        $window.on('scroll', function(){
            if($html.scrollTop() > 0) {
                scrollTop = $html.scrollTop();
            } else if($body.scrollTop() > 0) {
                scrollTop = $body.scrollTop();
            } else {
                scrollTop = 0;
            }
            

            if(scrollTop >= 0 && hasSplashLoad) {
                if(scrollTop > (introOffset / 3) ) {
                    if($body.hasClass('header-hidden')) {
                        $body.removeClass('header-hidden');
                    }
                } else {
                    if(!$body.hasClass('header-hidden')) {
                        $body.addClass('header-hidden');
                    }
                }

                if( scrollTop <= introOffset ) {
                    var scale =  (scrollTop / introOffset) * 4 + 1;
                    scale = 'scale(' + String(scale) + ')';
                    var top = Math.round( (scrollTop / introOffset) * 135) ;
                    top =  String(45 - top) + '%';
                    var down = Math.round( (scrollTop / introOffset) * 100) ;
                    down = String(-(down + 15) ) + '%';
                    /*var navTop = (scrollTop / introOffset) * ( navHeight + 20 );
                    navTop =  String(-(navHeight + 20) + navTop) + 'px';*/

                    $circleLoad.css('transform', scale);
                    $circleImage.css('top', top);
                    $hatDown.css('bottom', down);
                    //$nav.css('top', navTop);
                }
            }
        });
    }
} )(jQuery);

/* -------------------
# 4. Smooth scroll
------------------- */
( function($){
    if(typeof($) != 'undefined') {
        $html = $('html');
        $body = $('body');
        $nav  = $('.site-navigation');
        var navHeight = $nav.height();
        $burger = $(" [data-burger] ");
        $pageGenIsActive = ( document.getElementsByClassName('generate-code').length > 0) ? true : false;

        window.scrollTop = function() {
            return this.pageYOffset || document.documentElement.clientTop;
        }

        $links = $('.primary-menu a, .banner-menu a, .splash-load a, a.return');

        $links.each( function() {
            section = this.href.split('#');

            if( section.length > 1 ) {
                $(this).on('click', smoothScroll);
            }
        } );

        function smoothScroll(e) {
            if( this.href.split('#') ) {
                e.preventDefault();

                var href = ( e.currentTarget.href ) ? e.currentTarget.href : e.target.href;
                
                if(href) {
                    scroll(href.split('#')[1]);
                }
            }
        }

        function scroll(anchor) {
            var section = document.getElementById(anchor);
            if (section) {
                var scrollTop = Number(window.scrollTop());
                var offsetTop = Math.round(section.getBoundingClientRect().top);
                if(section.id == 'dobrodosli' && $pageGenIsActive != true) {
                    offsetTop += navHeight;
                }
                offsetTop = (offsetTop + scrollTop - navHeight) + 'px';
    
                $html.animate({scrollTop: offsetTop}, {duration: 600, easing: 'swing'});
                $body.animate({scrollTop: offsetTop}, {duration: 600, easing: 'swing'});

                if($nav.hasClass('expended')) {
                    $nav.removeClass('expended');
                    $burger.removeClass('close');
                }
            }
            else {
                window.location.href = "/#" + anchor;
            }
        }
    }
} )(jQuery);

/* ---------------------------------
# 5. Mobile Keyboard 
# 6. Age confirm
------------------------------------ */
( function($){
    /* -------------------
    # 5. Mobile Keyboard
    ---------------------- */ 

    $ageConfirm = $('.age-confirm');
    $wrapper = $('.age-confirm .wrapper');
    $isMobile = true;

    responsive.subscribe(function(){
        $isMobile = false;
    }, 'lg' );

    if($isMobile) {
        var screenHeight = window.innerHeight;
        var keyboardHeight = screenHeight / 2;
        
        var input = document.getElementById('day');
    
        $ageConfirm.on('click', function(e){
            if( e.target.tagName.toLowerCase() == 'input' ) {
                $wrapper.animate({'top': String(-keyboardHeight) + 'px'}, {duration: 300, easing: 'swing'});
            } else {
                $wrapper.animate({'top': String(0) + 'px'}, {duration: 300, easing: 'swing'});
            }
    
            if(event.keyCode==13){
    
            }
        });
    
        $(input).on('keyup', function(){
            if (event.keyCode === 13) {
                $wrapper.animate({'top': String(0) + 'px'}, {duration: 300, easing: 'swing'});
            }
        });
    }


    /* ---------------------------------
    # 6. Age confirm
    ------------------------------------ */
    if(typeof($) != 'undefined') {
        $enter = $('[data-age-check="enter"]'); 
        $input = $('[data-form="age-check"] input'); 
        $body = $('body');
        $html = $('html');
        $reset = $('[data-age-check="reset"]'); 
        $title = $('[data-age-check="title"]'); 
        //$navigation = $(" [data-navigation] ");

        $is_gen_page = $body.hasClass('generate-code');

        if(window.location.href.split('#').length > 1 || getCookie('age') == 'confirmed') {
            $body.attr('data-age', 'confirmed');

            if( $is_gen_page && $body.hasClass('header-hidden')) {
                $body.removeClass('header-hidden');
            }
        }

        $enter.on('click', function(e){
            e.preventDefault();

            if($isMobile) {
                $wrapper.animate({'top': String(0) + 'px'}, {duration: 300, easing: 'swing'});
            }

            if( ageCheck() ) {
                $body.attr('data-age', 'confirmed');
                $html.scrollTop(0);
                $body.scrollTop(0);
                setCookie('age', 'confirmed', 30);

                if( $is_gen_page && $body.hasClass('header-hidden')) {
                    $body.removeClass('header-hidden');
                }
            } else {
                $body.attr('data-age', 'denied');
                $title.html('<span class="iconfont exclamation"></span>Previše si mlad da bi bio LOCO!');
            }
        });

        function ageCheck() {
            var state;
            date = new Date();
            yearLimit = date.getFullYear() - 18;

            for(var i=0; i < $input.length; i++) {
                var state;
                state = ( !check(/[^\d]/, $input[i].value) && $input[i].value.length > 0) ? true : false;

                if(state){
                    state = check(/([0-9]{2})|([0-9]{1})/, $input[i].value);

                    if(i == 0 && Number($input[i].value) > 31){
                        state = false;
                        break;
                    } 

                    if(i == 1 && Number($input[i].value) > 12){
                        state = false;
                        break;
                    } 

                    if (i == ($input.length - 1) ){
                        if(!(Number($input[i].value) <= yearLimit)) {
                            state = false;
                            break;
                        }

                        if (!check(/([0-9]{4})/, $input[i].value) ) {
                            state = false;
                            break;
                        }
                    }
                } else {
                    break;
                }
            }

            function check(reg, el){
                return reg.test(el);
            }   

            $reset.on('click', function(){
                if( $body.attr('data-age') == 'denied' ) {
                    $body.attr('data-age', 'confirm');
                    $title.html('<span class="iconfont exclamation"></span>Pozdrav za sve zvezde večeri!');
                }
            });

            return state;
        }
    }

    /**
     * Cookie
     */
	function getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    function setCookie(name, value, days) {
        var d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    function deleteCookie(name) { 
        setCookie(name, '', -1); 
    }
} )(jQuery);